<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.uid" placeholder="请输入邀请人UID" clearable />
        </el-form-item>
        <el-select v-model="query.customerLevel" placeholder="请选择是否为高能经纪人" clearable style="margin-right:10px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="name"
        label="会员名"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="customerLevel"
        label="是否高能经纪人"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ row.customerLevel ===2 ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="uid"
        label="邀请人UID"
        align="center"
        show-overflow-tooltip
        width="200px"
      />
      <el-table-column
        prop="recommendedMembers"
        label="推荐会员数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="recommendCertification"
        label="推荐认证会员数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="rechargeHighCoinCount"
        label="充值高能金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="consumeHighCoinCount"
        label="已消费高能金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="getActivityCoinCount"
        label="获得活动金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="consumeActivityCoinCount"
        label="已消费活动金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="unlockCostOfHighPowerGoldCoins"
        label="项目被解锁消耗高能金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="unlockTheNumberOfActiveCoinsConsumed"
        label="项目被解锁消耗活动金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="rebateReward"
        label="现金返佣奖励"
        align="center"
        show-overflow-tooltip
      />
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/heigAgentData'
export default {
  mixins: [list],
  data() {
    return {
      api,
      options: [{ value: 1, label: '否' }, { value: 2, label: '是' }]
    }
  }
}
</script>

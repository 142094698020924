export default {
  data() {
    return {
      list: [],
      count: 0,
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50, 100],
      loading: false,
      query: {},
      queryTime: {},
      api: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    add() {
      this.$refs.form.open({ type: 'add' })
    },
    edit(id) {
      this.$refs.form.open({ type: 'edit', id })
    },
    detail(id) {
      this.$refs.form.detail(id)
    },
    del(id) {
      this.$confirm('确定要删除吗？', '提示')
        .then(() => {
          this.api.del(id)
            .then(() => {
              this.$message.success('删除成功')
              this.getList()
            })
        })
    },
    refresh() {
      this.query = {}
      this.page = 1
      this.getList()
      this.queryTime = {}
    },
    search() {
      this.page = 1
      this.getList()
    },
    getList() {
      this.loading = true
      this.api.getList({ ...this.query, page: this.page, pageSize: this.pageSize })
        .then(res => {
          const { list = [], count = 0 } = res.data
          this.list = list
          this.count = count
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}

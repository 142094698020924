import request from '@/utils/axios'

/** 获取高能经纪人数据 */
export function getList(params) {
  return request({
    url: '/broker/data/broker',
    method: 'get',
    params
  })
}
